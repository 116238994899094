<template>
  <div class="hsn-announcement">
    <a-modal :maskClosable="!isEdit && !isCreate" class="modal-card-announcement" centered :width="1200" :visible="visibleDetail" @cancel="closeDetail"  :title="!isChange ? null : isEdit ? 'Edit Announcement' : 'Create New Announcement'">
      <DetailAnnouncement @handle-change="handleChange" :contents="contents" :newData="newData" :classes="classes" :levels="levels" :isEdit="isEdit" :isCreate="isCreate" @showEdit="showEdit" @afterDelete="afterDelete" @addAttachment="addAttachment" @removeAttachment="removeAttachment" />
      <template slot="footer">
        <div :class="isChange ? null : 'd-none'">
          <a-button icon="close" @click.prevent="closeChange" type="danger">Cancel</a-button>
          <a-button icon="save" @click.prevent="handleOkModalManageAnnouncement" type="primary">Save</a-button>
        </div>
      <!-- <div class="d-flex mt-4 mb-0 justify-content-end" v-if="isChange"> -->
      </template>
    </a-modal>
    <div class="mb-3" :class="isDesktop ? 'd-flex' : null">
      <div>
        <div class="w-100">
          <a-input
            v-model="search"
            @change="fetchAnnouncements"
            placeholder="Search by title..."
            :style="{
              minWidth: !isDesktop ? null : '325px',
              height: '40px'
            }"
          ></a-input>
        </div>
      </div>
      <div :class="!isDesktop ? 'my-3' : 'ml-3'">
        <a-select
          :class="!isDesktop ? 'w-100' : null"
          v-model="type"
          @change="fetchAnnouncements"
          size="large"
          style="min-width: 150px;"
        >
          <a-select-option value="all">All Data</a-select-option>
          <a-select-option value="general">General</a-select-option>
          <a-select-option value="id_level">By Level</a-select-option>
          <a-select-option value="id_kelas">By Class</a-select-option>
        </a-select>
      </div>
      <div :class="!isDesktop ? 'w-100' : null" class="ml-auto" v-if="role !== 'Student'">
        <a-button
          class="w-100"
          @click.prevent="showDetail()"
          size="large"
          type="primary"
          ><a-icon type="plus" />Add Announcement</a-button
        >
      </div>
      <!-- <div class="ml-3">
        <a-select size="large" v-model="batch" @change="fetchData" style="min-width: 200px" placeholder="Pilih Tahun Masuk">
          <a-select-option v-for="tahun in years" :key="tahun.tahun" :value="tahun.tahun">{{tahun.tahun}}</a-select-option>
        </a-select>
      </div> -->
      <!-- <div class="ml-auto text-right">
        <a-button
        @click.prevent="showFilter = true"
        size="large" icon="filter" type="primary">SARING DATA</a-button>
      </div> -->
    </div>
    <div>
      <a-row :gutter="[16, 16]" v-if="datas.length">
        <a-col @click.prevent="showDetail(dat)" v-for="(dat, i) in datas" :key="i" :md="12" :lg="8" :sm="24">
          <a-card class="hsn-card-list" :class="(!dat.pengumuman_terbacas || !dat.pengumuman_terbacas.length) && role === 'Student' ? 'hsn-card-list-unread' : null">
            <template slot="title">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex">
                  <template v-for="(tag, j) in dat.tag">
                    <div v-if="j === 2 && dat.tag.length > 3" :key="j"><a-tag :color="tag.color" :key="j">+{{dat.tag.length - 2}} more</a-tag></div>
                    <div v-else-if="j < 2 || (j === 2 && dat.tag.length <= 3)" class="mb-2" :key="j + 'index'"><a-tag :color="tag.color" :key="j">{{tag.title}}</a-tag></div>
                  </template>
                </div>
                <div class="d-flex justify-content-end">
                  <a-tag v-if="role !== 'Student'" :color="dat.is_orangtua ? '#FAAD15' : '#5AA9A9'">{{dat.is_orangtua ? "Parent" : "Student"}}</a-tag>
                  <div v-if="(!dat.pengumuman_terbacas || !dat.pengumuman_terbacas.length) && role === 'Student'"><a-icon type="exclamation-circle" class="hsn-icon" /></div>
                </div>
              </div>
                <h3 class="title">{{dat.judul}}</h3>
                <p class="meta">Last modified {{dat.lastModified}} by {{dat.guru ? dat.guru.nama : 'Admin'}}</p>
            </template>
            <div class="announcement-content h-100">
              <span v-html="clearTag(dat.deskripsi)" />
            </div>
          </a-card>
        </a-col>
      </a-row>
      <div v-else style="min-height: 300px;" class="d-flex align-items-center justify-content-center">
        <EmptyState
          heading="No Data"
          description="You have no announcement"
          />
      </div>
    </div>
  </div>
</template>

<script>
// const CardList = () => import('@/components/app/shared/CardList')
import moment from 'moment'
import config from '@/config'
import axios from 'axios'
import { clearTag } from '@/helpers/regex.js'
const EmptyState = () => import('@/components/app/EmptyState')
const DetailAnnouncement = () => import('@/components/app/DetailAnnouncement')

export default {
  components: { EmptyState, DetailAnnouncement },
  data() {
    return {
      search: null,
      datas: [],
      page: 1,
      type: 'all',
      isEdit: false,
      isCreate: false,
      newData: { id_level: [], id_kelas: [], target: 'murid', attachments: [] },
      contents: {},
      visibleDetail: false,
      levels: [],
      classes: [],
      loadingActionModalManageAnnouncement: false,
      loadingDeleteAnnouncement: false,
    }
  },
  methods: {
    moment,
    clearTag,
    handleChange(col) {
      if (col === 'type') {
        if (this.newData.type === 'general') {
          this.newData.id_level = []
          this.newData.id_kelas = []
        } else if (this.newData.type === 'id_kelas') this.newData.id_level = []
        else if (this.newData.type === 'id_level') this.newData.id_kelas = []
      }
    },
    async handleOkModalManageAnnouncement() {
      // console.log('this.isEdit', this.isEdit)
      const content = `${this.isEdit ? 'Are you sure want to edit this announcement?' : 'Are you sure want to create new announcement'}`
      if (this.isValidateForm) {
        this.$notification.error({
          message: 'Warning',
          description: 'All fields are required',
        })
      } else {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>{content}</div>
          ),
          onOk: async() => {
            this.loadingActionModalManageAnnouncement = true
            const formData = new FormData()
            const id = this.contents.id || null
            let idLevel
            let idKelas
            if (this.newData.id_level?.length) idLevel = JSON.stringify([...this.newData.id_level])
            if (this.newData.id_kelas?.length) idKelas = JSON.stringify([...this.newData.id_kelas])
            const type = this.newData.type || null
            const judul = this.newData.judul || null
            const deskripsi = this.newData.deskripsi || null
            const isOrangtua = this.newData.target === 'orangtua'
            const attachments = this.newData.attachments
            const deletedFiles = this.newData.deletedFiles
            if (id) formData.append('id', id)
            if (idLevel) formData.append('id_level', idLevel)
            if (idKelas) formData.append('id_kelas', idKelas)
            if (type) formData.append('type', type)
            if (judul) formData.append('judul', judul)
            if (deskripsi) formData.append('deskripsi', deskripsi)
            if (isOrangtua) formData.append('is_orangtua', isOrangtua)
            if (this.role === 'Admin') formData.append('isAdmin', true)
            for (let i = 0; i < attachments.length; i++) {
              formData.append('files', attachments[i])
            }
            if (this.isEdit) {
              for (let i = 0; i < deletedFiles.length; i++) {
                formData.append('deletedFiles', deletedFiles[i])
              }
            }
            try {
              if (this.isEdit) {
                const updated = await axios.put(`${config.apiUrl}/api/pengumuman/${id}`, formData, {
                  headers: {
                    token: localStorage.token,
                  },
                })
                this.loadingActionModalManageAnnouncement = false
                this.closeDetail()
                this.fetchAnnouncements()
                if (updated.status === 200) {
                  this.$notification.success({
                    message: 'Success',
                    description:
                      'Announcement has been edited',
                  })
                }
              } else {
                const created = await axios.post(`${config.apiUrl}/api/pengumuman/${this.role.toLowerCase()}`, formData, {
                  headers: {
                    token: localStorage.token,
                  },
                })
                this.loadingActionModalManageAnnouncement = false
                this.closeDetail()
                this.fetchAnnouncements()
                if (created.status === 200) {
                  this.$notification.success({
                    message: 'Success',
                    description:
                      'Announcement has been created',
                  })
                }
              }
            } catch (error) {
              this.loadingActionModalManageAnnouncement = false
              this.closeDetail()
              console.log(error)
              this.$notification.error({
                message: 'ERROR',
                description: error,
              })
            }
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.isEdit ? 'Edit Now' : 'Create Now',
          cancelText: 'Cancel',
        })
      }
    },
    afterDelete() {
      this.fetchAnnouncements()
      this.visibleDetail = false
    },
    searchAction(e) {
      console.log(e.target.value)
    },
    async fetchAnnouncements() {
      try {
        const { type, search: judul, role } = this
        const data = await this.$store.dispatch('announcement/FETCH_ALL_ANNOUNCEMENT', { type, judul, role })
        this.datas = data.map(dat => {
          const tags = []
          let type = 'general'
          const { penerima_pengumumans: recipients } = dat
          if (recipients?.filter(pen => pen.id_kelas).length) type = 'id_kelas'
          else if (recipients?.filter(pen => pen.id_level).length) type = 'id_level'

          if (recipients?.length) {
            for (let i = 0; i < recipients?.length; i++) {
              let tag = {}
              const recipient = recipients[i]
              if (!recipient?.id_kelas && !recipient?.id_level) {
                tag = { title: 'General', color: 'green' }
                type = 'general'
              } else {
                if (recipient?.id_kelas) {
                  const kelas = `${recipient?.kela.level.nama}-${recipient?.kela.simbol}`
                  tag = { title: kelas, color: 'orange' }
                  type = 'id_kelas'
                } else if (recipient?.id_level) {
                  const level = recipient?.level.nama
                  tag = { title: level, color: 'blue' }
                  type = 'id_level'
                }
              }
              tags.push(tag)
            }
          } else tags.push({ title: 'General', color: 'green' })
          return {
            ...dat,
            tag: tags,
            type,
            lastModified: moment(dat.updatedAt, 'YYYY-MM-DD HH:mm').format('DD MMM YYYY, HH:mm'),
            firstCreated: moment(dat.createdAt, 'YYYY-MM-DD HH:mm').format('DD MMM YYYY, HH:mm'),
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
    showDetail(data) {
      this.visibleDetail = true
      this.newData = { id_level: [], id_kelas: [], target: 'murid', attachments: [], deletedFiles: [] }
      if (data) {
        this.contents = data
        const idLevel = data.penerima_pengumumans?.filter(pen => pen.id_level)?.map(pen => pen.id_level)
        const idKelas = data.penerima_pengumumans?.filter(pen => pen.id_kelas)?.map(pen => pen.id_kelas)
        const target = data.is_orangtua ? 'orangtua' : 'murid'
        this.newData = { ...this.newData, ...this.contents, id_level: idLevel, id_kelas: idKelas, target, attachments: [], deletedFiles: [] }
        if (!data.pengumuman_terbacas?.length && this.role === 'Student') {
          const payload = { id_announcement: data.id }
          this.$store.dispatch('announcement/UPDATE_STATUS_READ', payload)
        }
      } else this.isCreate = true
    },
    closeDetail() {
      this.isCreate = false
      this.isEdit = false
      this.newData = { id_level: [], id_kelas: [], target: 'murid', attachments: [], deletedFiles: [] }
      this.contents = {}
      this.visibleDetail = false
      this.fetchAnnouncements()
    },
    showEdit() {
      this.isEdit = !this.isEdit
      this.newData.files = [...this.contents.files]
      this.newData.attachments = []
      this.newData.deletedFiles = []
    },
    closeChange() {
      if (this.isEdit) this.isEdit = false
      else if (this.isCreate) {
        this.isCreate = false
        this.visibleDetail = false
      }
    },
    async fetchLevels() {
      try {
        const data = await this.$store.dispatch('master/FETCH_LEVEL', { page: 'all' })
        this.levels = data.map(level => {
          return {
            nama: level.nama,
            id: level.id,
          }
        })
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async fetchClasses() {
      try {
        const data = await this.$store.dispatch('admin/FETCH_CLASS', { page: 'all' })
        this.classes = data.map(kelas => {
          const { id } = kelas
          return {
            id,
            title: `${kelas.level.nama} - ${kelas.simbol}`,
          }
        })
        // console.log(data)
      } catch (err) {
        console.log(err)
      }
    },
    saveEdit() {
      console.log('tes')
    },
    addAttachment(file) {
      this.newData.attachments.push(file)
      return false
    },
    removeAttachment(file, isOld = false) {
      // console.log(index, this.newData.attachments)
      if (isOld) {
        this.newData.files = this.newData.files.filter((f) => file.id !== f.id)
        if (!this.newData.deletedFiles.includes(file.id)) this.newData.deletedFiles.push(file.id)
      } else this.newData.attachments = this.newData.attachments.filter((f) => file.uid !== f.uid)
    },
  },
  created() {
    this.fetchAnnouncements()
    if (this.role !== 'Student') {
      this.fetchLevels()
      this.fetchClasses()
    }
  },
  computed: {
    isValidateForm() {
      const { type, judul, target, id_kelas: idKelas, id_level: idLevel } = this.newData
      if (type && target && judul && ((type === 'id_kelas' && idKelas.length) || (type === 'id_level' && idLevel.length) || type === 'general')) {
        return false
      }
      return true
    },
    isChange() {
      return this.isEdit || this.isCreate
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
    role() {
      const role = this.$store.state.user.role
      if (role.includes('guruMapel')) {
        return 'Teacher'
      } else if (role.includes('murid')) {
        return 'Student'
      } else if (role.includes('admin')) {
        return 'Admin'
      } else {
        return 'Other'
      }
    },
  },
}
</script>

<style lang="scss">
.hsn-announcement {
  .hsn-card-list {
    .title {
      height: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .meta {
      font-size: 12px!important;
      color: #c5c5c5;
    }

    // &-unread {
    //   border: none;
    //   background-color: rgba(64, 185, 131, 0.15);
    //   .meta {
    //     color: #a2adb9;
    //   }
    //   .ant-card-head {
    //     border-bottom: 1px solid #ffffff80;
    //   }
    // }

    .hsn-icon {
      font-size: 22px;
      color: rgb(250, 173, 21);
    }

    .announcement-content {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 0.80rem;
      line-height: 23px;
    }
  }
}

.hsn-card-list {
  cursor: pointer;
  border-radius: 8px;
  transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
  height: 225px!important;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    // transform: scale(1.01)
    transform: translate(0, -5px);
    box-shadow: 0px 0px 20px #0419301a;
  }

}
</style>
